.phi-player {
  width: 100%;
  max-width: 100%;
  position: absolute;
  height: 100%;
}

video::-webkit-media-controls {
  display: none;
}

/* hide metadata text track on safari */
.vjs-captions-menu-item {
  display: none;
}

.vjs-texttrack-settings {
  display: none;
}

/* ::cue {
  background: none;
  padding-bottom: 5px;
} */

/* video::-webkit-media-text-track-display-backdrop {
  background-color: #FFFFFF;
} */
/* video::-webkit-media-text-track-display {
  background-color: #FFFFFF;
} */

/* video::-webkit-media-text-track-container {
  padding-bottom: 10px;
} */
/* video::-webkit-media-text-track-background {
  background-color: #FFFFFF;
}
video::-webkit-media-text-track-display {
  background-color: #FFFFFF;
} */