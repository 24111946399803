.watermarkDateTime {
  position: absolute;
  top: 1%;
  right: 5px;
  display: flex;
  align-items: center;
  pointer-events: none;
  /* -webkit-text-stroke: 1px black; */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.watermarkDateTime > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150%;
  font-weight: bold;
}
.watermarkDateTime > div:nth-child(1) {
  padding-right: 5px;
}

.watermarkStudent {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  pointer-events: none;
  opacity: 0.5;
  transform: translateX(-50%) translateY(-50%) rotate(-30deg);
}
.watermarkStudent > div {
  color: #9C9C9C;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200%;
  font-weight: bold;
}

.watermarkBottom {
  position: absolute;
  bottom: 1%;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 10%;
  opacity: 0.8;
  pointer-events: none;
}
#watermarkLogo {
  display: flex;
  align-items: center;
  align-self: flex-start;
  height: 100%;
  pointer-events: none;
  margin-right: 5px;
  margin-left: 5px;
}
#watermarkLogo > img {
  height: 80%;
}
#watermarkCourse {
  display: flex;
  align-items: center;
  /* align-items: flex-end; */
  justify-content: center;
  pointer-events: none;
  height: 100%;
  width: 50%;
  min-width: 0;
  margin-left: 5px;
}
#watermarkCourse > div {
  width: 100%;
  font-size: 200%;
  /* line-height: 220%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#watermarkTeacher {
  display: flex;
  align-items: center;
  pointer-events: none;
  height: 100%;
  margin-left: auto;
  padding-right: 5px;
}
#watermarkTeacher > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200%;
}

.warning {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.8;
  padding: 50px;
}
.warning > img {
  width: 5%;
  margin-right: 10px;
}
.warning > div {
  color: lightgray;
  font-size: 200%;
}

@media screen and (max-width: 768px) {
  #watermarkCourse > div,
  #watermarkTeacher > div {
    font-size: 150%;
  }
  .watermarkStudent > div {
    font-size: 150%;
  }
}

@media screen and (max-width: 500px) {
  #watermarkCourse > div,
  #watermarkTeacher > div {
    font-size: 100%;
  }
  .watermarkStudent > div {
    font-size: 100%;
  }
}